$nav-height: 4em !default;

@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // ma

.site-logo img {
  max-height: 4rem;
}

.feature__item a {
    text-decoration: none;
    box-shadow: none;
}

.archive__item-teaser {
    height: 200px;
}

.archive__item-excerpt {
    height: 80px;
}

.archive__item-teaser img {
    max-height: 100%;
    height: auto;
    width: inherit;
}

.blog__item-excerpt {
    height: 100%;
    margin-top: 0;
    font-size: 0.75em;
}
